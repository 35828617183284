<!--
 * @Author: tangshuo
 * @Date: 2021-01-21 19:50:14
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-12-30 11:59:02
 * @Remarks:
-->
<template>
  <el-dialog
    :modal="showModal"
    @open="opened()"
    :visible.sync="isShowTip"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :center="true"
    width="500px"
    top="0"
    custom-class="tip-dig"
  >
    <div class="tip-main flex flex-c">
      <ConIcon icon="icon_close" @click="closeTipDig"></ConIcon>
      <i class="iconfont tip-icon" :class="iconClass"></i>
      <span class="tip-span" v-if="tipText" v-html="tipText"></span>
      <p class=" " v-if="lessText">
        {{ lessText }}
      </p>
      <div class="del-xcx-box">
        <el-checkbox v-if="isShowDelxcx" v-model="isDel">{{ delxcxText || '同时删除小程序案例'}}</el-checkbox>
      </div>
      <slot name="main"></slot>
      <div class="operate-box">
        <ConButton
          :type="confirmType"
          :title="confirmText"
          @click="confirmClick"
        ></ConButton>
        <ConButton
          v-if="isShowCancel"
          :type="cancelType"
          :title="cancelText"
          @click="cancelClick"
        ></ConButton>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isDel: false, //是否同时删除小程序案例
    };
  },
  props: {
    showModal: {
      // 是否显示遮罩层
      type: Boolean,
      default: true,
    },
    type: {
      //提示类型 err错误提示 warn警告提示 suc成功提示(默认)
      type: String,
      default: "suc",
    },
    isShowTip: {
      //是否显示弹窗
      default: false,
    },
    tipText: {
      //主要提示文字
      type: String,
      default: "确定要执行该操作吗？",
    },
    lessText: {
      //次要提示文字
      type: String,
      default: "",
    },
    confirmType: {
      //确认按钮类型
      type: String,
      default: "con",
    },
    isShowCancel: {
      //是否显示取消按钮
      type: Boolean,
      default: true,
    },
    isShowDelxcx: {
      //是否显示删除小程序操作
      type: Boolean,
      default: false,
    },
    delxcxText: {
      type: String,
      default: "",
    },
    cancelType: {
      //取消按钮类型
      type: String,
      default: "cancel",
    },
    confirmText: {
      //确认按钮文字
      type: String,
      default: "确认",
    },
    cancelText: {
      //取消按钮文字
      type: String,
      default: "取消",
    },
  },
  computed: {
    iconClass() {
      let icon = "btn_sel";
      switch (this.type) {
        case "err":
          icon = "icon_error";
          break;
        case "warn":
          icon = "icon_danger";
          break;
        default:
          break;
      }
      return icon;
    },
  },
  created() {},
  mounted() {},
  methods: {
    opened() {
      this.isDel = true;
    },
    confirmClick() {
      this.$emit("confirmClick", this.isDel);
      this.closeTipDig();
    },
    cancelClick() {
      this.$emit("cancelClick");
    },
    closeTipDig() {
      this.$emit("closeTipDig");
    },
  },
};
</script>

<style lang="scss" scoped>
.tip-main {
  .icon_close {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .tip-icon {
    font-size: 48px;
    color: $font-color-import;
  }
  .icon_error {
    color: $err-color;
  }
  .icon_danger {
    color: $warn-color;
  }

  .tip-span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    @include themify($themes) {
      color: themed("input-color");
    }
    line-height: 24px;
    margin-top: 16px;
    text-align: center;
  }
  .tip-p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-top: 8px;
    text-align: center;
  }
  .del-xcx-box {
    line-height: 32px;
  }
}
::v-deep .tip-main .operate-box {
  margin-top: 24px;
  display: flex;
  .el-button {
    min-width: 80px;
  }
  .el-button + .el-button {
    margin-left: 16px;
  }
}

::v-deep .tip-dig {
  width: 500px;
  margin: 0 auto;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  @include themify($themes) {
    background: themed("main-bg");
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 48px;
  }
}
</style>
