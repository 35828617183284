<!--
 * @Author: tangshuo
 * @Date: 2021-12-21 18:39:09
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-12-23 15:56:32
 * @Remarks: 新增颜色
-->
<template>
  <ConDig
    :title="`${curColor && curColor.ID ? '编辑' : '新增'}颜色`"
    width="480px"
    :isShowDig="showAddColorDig"
    :loading="isLodding"
    :appendToBody="true"
    :clickModal="false"
    confirmText="保存"
    @open="initData"
    @closed="showAddColorDig = false"
    @closeDig="handleClose"
    @cancelClick="handleClose"
    @confirmClick="addNewColor"
  >
    <main slot="dialogMain" class="add-color-box">
      <ConInput
        v-model.trim="colorName"
        maxlength="6"
        showWordLimit
        :size="24"
        class="input-name"
        placeholder="颜色名称"
      ></ConInput>
      <div class="color-sel-box">
        <span class="sel-title">选择颜色或输入色值</span>
        <el-color-picker v-model="colorValue"></el-color-picker>
        <ConInput
          v-model.trim="colorValue"
          class="value-input"
          maxlength="30"
          placeholder="颜色色值"
        ></ConInput>
      </div>
    </main>
  </ConDig>
</template>

<script>
import { addBranchColor, updateBranchColor } from "@/api/case";
export default {
  data() {
    return {
      isLodding: false,
      colorName: "",
      colorValue: "",
      colorID: "",
    };
  },
  props: {
    showAddColorDig: {
      type: Boolean,
      default: false,
    },
    curColor: {
      default: () => {},
    },
  },
  created() {},
  mounted() {},
  methods: {
    initData() {
      this.colorName = "";
      this.colorValue = "";
      if (this.curColor) {
        this.colorName = this.curColor.ColorName;
        this.colorValue = this.curColor.ColorValue;
      }
    },
    /**
     * 新增颜色
     */
    addNewColor() {
      if (!this.colorName) {
        this.$Message("请输入颜色名称");
        return;
      }
      if (!this.colorValue) {
        this.$Message("请选择颜色色值");
        return;
      }
      if (this.curColor && this.curColor.ID) {
        this.updateBranchColor();
        return;
      }
      this.isLodding = true;
      addBranchColor({
        ColorValue: this.colorValue,
        ColorName: this.colorName,
      })
        .then((res) => {
          if (res.status) {
            this.$Success("添加成功");
            this.isChange = true;
            this.handleClose();
          } else {
            this.$Error(res.msg ? res.msg : "添加失败");
          }
          this.isLodding = false;
        })
        .catch((err) => {
          console.log(err);
          this.$Error("添加失败");
          this.isLodding = false;
        });
    },
    /**
     * 编辑颜色系
     */
    updateBranchColor() {
      this.isLodding = true;
      updateBranchColor({
        ID: this.curColor.ID,
        ColorValue: this.colorValue,
        ColorName: this.colorName,
      })
        .then((res) => {
          if (res.status) {
            this.$Success("编辑成功");
            this.isChange = true;
            this.handleClose();
          } else {
            this.$Error(res.msg ? res.msg : "编辑失败");
          }
          this.isLodding = false;
        })
        .catch((err) => {
          console.log(err);
          this.$Error("编辑失败");
          this.isLodding = false;
        });
    },
    handleClose() {
      this.$emit("handleClose", this.isChange);
    },
  },
};
</script>

<style scoped lang="scss">
.add-color-box {
  padding: 24px;
  .input-name {
    ::v-deep .el-input__inner {
      height: 60px;
      line-height: 60px;
    }
  }
  .color-sel-box {
    width: 100%;
    height: 90px;
    box-sizing: border-box;
    border-top: none;
    padding: 12px 0;
    display: flex;
    position: relative;
    justify-content: flex-end;
    ::v-deep .el-color-picker__trigger {
      width: 100px;
      padding: 0;
      border: none;
      border-radius: 0;
      .el-color-picker__color {
        border-radius: 0;
        border-color: #eee;
        @include themify($themes) {
          border-color: themed("search-his-bg-h");
        }
      }
    }
    .sel-title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 40px;
      margin-right: 12px;
      flex-shrink: 0;
      position: absolute;
      left: 0;
      @include themify($themes) {
        color: themed("set-item-color");
      }
    }
    .value-input {
      width: 100px;
      margin-left: 12px;
    }
  }
}
</style>
