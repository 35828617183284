/*
 * @Author: tangshuo
 * @Date: 2020-08-10 15:01:41
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-02-02 10:33:33
 * @Remarks: 案例相关store
 */
import {
	getFileALBQInfo,
	getDicInfoByDicName,
	getFileQDYSInfo,
	getDicOrderTypeList,
	GetBranchLabelList_DJ,
} from "@/api/case";
export default {
	namespaced: true,
	state: {
		cityList: [], //城市列表
		orderTypeList: [],//订单类型(庆典类型)
		branchLables: [], //店铺标签列表
		allLabelList: [], // 标签 all 展开成一级
		branchColors: [], //店铺颜色列表
		userList: [], // 人员
		showLinkIndex: 0,
	},
	mutations: {
		//设置店铺案例标签信息
		setBranchLables(state, data) {
			let allLabelList = [];
			function dataFormat(list,item) {
				if (list && list.length > 0) {
					list.forEach(c => {
						c.RelationTypes = c.RelationTypes ? c.RelationTypes.split(',') : [];
						if (item) {
							c.RelationTypes = item.RelationTypes;

							c.name = item.name + '/' + c.LabelContent;
							c.value = [...item.value, c.Id];
						} else {
							c.name = c.LabelContent;
							c.value = [c.Id];
						}
						c.selLabel = [];
						c.selPanelValue = [];

						allLabelList.push({
							LabelContent: c.LabelContent,
							Id: c.Id,
							ParentId: c.ParentId,
							name: c.name,
							value: c.value,
							RelationTypes: c.RelationTypes,
						})
						if (!c.ChildList || c.ChildList.length <= 0) {
							delete c.ChildList;
						} else {
							dataFormat(c.ChildList,c);
						}
					})
				}
			}
			dataFormat(data);
			state.allLabelList = allLabelList;
			state.branchLables = data;
		},
		//设置店铺案例颜色信息
		setBranchColors(state, data) {
			state.branchColors = data;
		},
		//设置订单类型(庆典类型)
		setOrderTypeList(state, data) {
			let dataList = data && data.length > 0 ? data : [];
			state.orderTypeList = dataList.filter(c => c.Isused);
		},
	},
	getters: {},
	actions: {
		// 获取店铺案例标签信息 分级
		GetBranchLabelList_DJ(store, params) {
			return new Promise(resolve => {
				GetBranchLabelList_DJ(params).then(res => {
					if (res.status && res.data) {
						const data = res.data || [];
						store.commit("setBranchLables", data);
					}
					resolve(res);
				}).catch(err => {
					resolve(null);
				})
			})
		},
		// //获取店铺案例标签信息
		// getFileALBQInfo(store, params) {
		// 	getFileALBQInfo().then(res => {
		// 		if (res.status && res.data) {
		// 			store.commit("setBranchLables", res.data);
		// 		}
		// 		params ? params.callback() : null;
		// 	}).catch(err => {
		// 		params ? params.callback() : null;
		// 	})
		// },
		//获取店铺案例颜色信息
		getFileQDYSInfo(store, params) {
			getFileQDYSInfo().then(res => {
				if (res.status && res.data) {
					store.commit("setBranchColors", res.data);
				}
				params ? params.callback(res) : ''
			})
		},
		//获取订单类型(庆典类型)
		getDicInfoByDicName(store, params) {
			getDicInfoByDicName(params.dicName).then(res => {
				if (res.status && res.data) {
					if (params.dicName === '庆典类型') {
						store.commit("setOrderTypeList", res.data);
					}
				}
				params && params.callback ? params.callback() : ''
			})
		},
		//获取订单类型(庆典类型)
		getDicOrderTypeList(store, params) {
			getDicOrderTypeList(params.SearchBranchId).then(res => {
				if (res.status && res.data) {
					store.commit("setOrderTypeList", res.data);
				}
				params && params.callback ? params.callback() : ''
			})
		},
	}
}
