<!--
 * @Author: tangshuo
 * @Date: 2021-10-09 16:48:52
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-10-11 17:50:03
 * @Remarks: 登录背景自定义
-->
<template>
  <ConDig
    title="登录背景自定义"
    width="480px"
    :isShowDig="isShowCustom"
    :loading="isLoading"
    :appendToBody="true"
    :isShowFooter="false"
    @open="initData"
    @closed="isShowCustom = false"
    @closeDig="closeCustomDig"
    @cancelClick="closeCustomDig"
  >
    <main
      slot="dialogMain"
      class="custom-title-main back-ground-main"
      v-loading="isLoading"
    >
      <p class="top-p">
        支持图片/视频格式，建议尺寸1920*1080（16:9），大小限制20M，若因电脑型号差异导致背景显示不完整，属于正常情况。
      </p>
      <div class="imgbox-box">
        <div class="img-box add-box" @click="changeIcon">
          <i class="iconfont icon_add_big add-icon"></i>
        </div>
        <div
          class="img-box"
          v-for="(item, index) of loginUrls"
          :key="index"
          @click="setLoginCoverBg(item, index)"
        >
          <img
            class="back-img"
            v-if="item.fileThSrc"
            :src="item.fileThSrc ? item.fileThSrc : defaultUrl"
          />
          <div class="video-mask">
            <i v-if="item.IsCover" class="iconfont btn_sel2 select-icon"></i>
            <img
              class="player-icon"
              v-if="item.FileType == 2"
              src="images/home/plays-icon.png"
            />
            <i
              class="del-icon el-icon-delete"
              v-if="!item.IsCover && !item.IsDefault"
              @click.stop="removeCover(index)"
            ></i>
            <span class="default-text" v-if="item.IsDefault">官方默认</span>
          </div>
        </div>
      </div>

      <div class="btn-box" v-if="false">
        <el-button class="save-btn" @click="changeIcon">替换</el-button>
        <el-button
          class="close-btn"
          @click="
            loginUrl = defaultUrl;
            setLoginBg();
          "
          >恢复默认</el-button
        >
      </div>
      <input
        type="file"
        id="loginfile"
        ref="loginfile"
        accept="image/png, image/jpeg, image/gif, video/mp4, video/x-m4v, video/quicktime"
        style="opacity: 0; width: 0; height: 0; position: absolute"
        @change="appendPicture"
      />
      <video
        id="upvideo"
        :src="videoSrc"
        style="opacity: 0; position: absolute; top: -99999px"
        crossorigin="anonymous"
        @loadeddata="setVideoCover"
      ></video>
    </main>
  </ConDig>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      curFile: {},
      loginUrl: "",
      loginUrls: [],
      isVideo: false,
      cosIp: sessionStorage.getItem("BranchCosCdnUrl"),
      acceptImage: [
        {
          type: "image/png",
          typeStr: ".png",
        },
        {
          type: "image/jpeg",
          typeStr: ".jpg|.jpeg",
        },
        {
          type: "image/gif",
          typeStr: ".gif",
        },
      ], //图片限制格式列表
      acceptVideo: [
        {
          type: "video/mp4",
          typeStr: ".mp4",
        },
        {
          type: "video/x-m4v",
          typeStr: ".m4v",
        },
        {
          type: "video/quicktime",
          typeStr: ".mov",
        },
      ], //视频限制格式列表
      videoSrc: "",
      defaultUrl:
        "https://weddingbo-1256393015.cos.ap-chengdu.myqcloud.com/1/CaseReveal/d1e2055c-6012-7e10-5289-ef41e8b57e82/Videos/106966f5-958c-6850-b6b6-f9a92ae26752.mp4",
      isLoading: false,
    };
  },
  props: {
    isShowCustom: {
      default: false,
    },
  },
  computed: {
    ...mapState({
      authList: (state) => state.authList,
    }),
  },
  created() {},
  mounted() {},
  methods: {
    initData() {
      this.getLoginUrl();
    },
    removeCover(index) {
      this.loginUrls.splice(index, 1);
      this.setLoginBg();
    },
    /**
     * 获取视频第一帧作为封面
     */
    setVideoCover() {
      let video;
      let scale = 0.8;
      video = $("#upvideo").get(0);
      let canvas = document.createElement("canvas");
      let _this = this;
      canvas.width = video.videoWidth * scale;
      canvas.height = video.videoHeight * scale;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((imgfile) => {
        let url = "";
        if (window.createObjectURL != undefined) {
          // basic
          url = window.createObjectURL(imgfile);
        } else if (window.URL != undefined) {
          // mozilla(firefox)
          url = window.URL.createObjectURL(imgfile);
        } else if (window.webkitURL != undefined) {
          // webkit or chrome
          url = window.webkitURL.createObjectURL(imgfile);
        }
        let file = _this.loginUrls.find(
          (f) => f.fileSrc === _this.videoSrc.split("#")[0]
        );
        file.fileThSrc = url;
        this.$forceUpdate();
        file.coverFile = imgfile;
        file.coverFile.name = "VideoCover.png";
        let videoList = this.loginUrls.filter(
          (w) => w.FileType == 2 && !w.coverFile && !w.fileThSrc
        );
        if (videoList.length > 0) {
          let issafariBrowser = !/Chrome/.test(navigator.userAgent);
          let videoSrc = videoList[0].fileSrc + "#t=3";
          if (issafariBrowser) {
            videoSrc = videoList[0].fileSrc;
          }
          _this.videoSrc = videoSrc;
        } else {
          _this.upLoadFile();
        }
      });
    },
    /**
     * 获取登录背景
     */
    getLoginUrl() {
      this.isLoading = true;
      this.$axios({
        url: "NewWeddingApi/SystemManage/Branch/GetLogImgUrl_New",
        method: "POST",
        success(res) {
          this.isLoading = false;
          if (res && res.data.status) {
            let fileList = res.data.data || [];
            fileList.forEach((f) => {
              f.fileSrc = f.FilePath;
              f.fileThSrc = f.FileOtherPath;
            });
            this.loginUrls = fileList;
          } else {
            this.$Error(res.data.msg ? res.data.msg : "获取失败");
          }
        },
        error(err) {
          this.$Error("获取失败");
          this.isLoading = false;
          console.log(err);
        },
      });
    },
    /**
     * 模拟点击 打开文件选择框
     */
    changeIcon() {
      $("#loginfile").val(""); //赋值为'',防止选择同一文件不触发change事件
      $("#loginfile").trigger("click");
    },
    /**
     * 选择文件后
     */
    appendPicture() {
      var imgNum = document.getElementById("loginfile").files.length; //文件数量
      for (let i = 0; i < imgNum; i++) {
        let imgNews = document.getElementById("loginfile").files[i];
        let acceptList = [...this.acceptImage, ...this.acceptVideo];
        let fileType = imgNews.type;
        if (
          !acceptList.find((f) => f.type === fileType) ||
          imgNews.size > 20 * 1024 * 1024
        ) {
          this.$Message(
            `仅支持以下格式:${acceptList
              .map((a) => a.typeStr)
              .join("|")},大小20M以内,不符合文件已过滤`
          );
          i = imgNum;
        }
      }
      for (let i = 0; i < imgNum; i++) {
        let imgNews = document.getElementById("loginfile").files[i];
        let acceptList = [...this.acceptImage, ...this.acceptVideo];
        let fileType = imgNews.type;
        if (
          acceptList.find((f) => f.type === fileType) &&
          imgNews.size <= 20 * 1024 * 1024
        ) {
          let fileId = this.$newGuid();
          this.loginUrls.unshift({
            file: imgNews,
            fileSrc: this.getObjectURL(imgNews),
            fileThSrc:
              fileType.indexOf("image") != -1 ? this.getObjectURL(imgNews) : "",
            isEdit: false,
            uploadState: 1, //上传状态 1：未上传 2：已上传 3：上传失败 4：待上传(已加入队列) 5：正在上传
            FileType: fileType.indexOf("image") != -1 ? 1 : 2,
            fileId: fileId,
          });
        }
      }
      let videoList = this.loginUrls.filter(
        (w) => w.FileType == 2 && !w.coverFile && !w.fileThSrc && !w.Id
      );

      if (videoList.length > 0) {
        let issafariBrowser = !/Chrome/.test(navigator.userAgent);
        let videoSrc = videoList[0].fileSrc + "#t=3";
        if (issafariBrowser) {
          videoSrc = videoList[0].fileSrc;
        }
        this.videoSrc = videoSrc;
      } else {
        this.upLoadFile();
      }
    },
    upLoadFile() {
      let self = this;
      if (!this.loginUrls[0].file) return;
      this.$newUploadFile({
        file: this.loginUrls[0].file,
        fileId: "",
        pathKey: `${sessionStorage.getItem("BranchId")}/LoginBG/`,
        thFile:
          this.loginUrls[0].FileType == 2 ? this.loginUrls[0].coverFile : "", //压缩文件(仅非图片文件有效)
        ysType: 2,
        onProgress(progress) {
          //根据文件id查找相应文件更改其上传进度
          // self.curFile.progress = progress;
          self.$forceUpdate();
        },
        onTaskReady(taskId) {},
        success(res) {
          // console.log("上传成功", res);
          let path = res.key;
          let ysPath = res.ysKey;
          //反写数据
          self.loginUrls[0].fileSrc = path ? self.cosIp + path : "";
          self.loginUrls[0].fileThSrc = ysPath ? self.cosIp + ysPath : "";

          self.loginUrls[0].FilePath = path ? self.cosIp + path : "";
          self.loginUrls[0].FileOtherPath = ysPath ? self.cosIp + ysPath : "";
          //设置当前为封面
          self.loginUrls.forEach((c, i) => (c.IsCover = i === 0));
          self.setLoginBg();
          self.$forceUpdate();
        },
        error() {},
      });
    },
    /**
     * 本地文件转为可播放Url
     */
    getObjectURL(file) {
      var url = null;
      if (window.createObjectURL != undefined) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      }
      return url;
    },
    setLoginCoverBg(item, index) {
      if (!item.IsCover) {
        this.loginUrls.forEach((u, i) => (u.IsCover = i === index));
        this.setLoginBg();
      }
    },
    closeCustomDig() {
      this.$emit("closeCustomDig");
    },
    setLoginBg() {
      let list = [];
      this.loginUrls
        .filter((l) => !!l.FilePath)
        .forEach((file) => {
          list.push({
            FilePath: file.FilePath,
            FileOtherPath: file.FileOtherPath,
            FileType: file.FileType,
            IsCover: !!file.IsCover,
            IsDefault: !!file.IsDefault,
          });
        });
      this.$axios({
        url: "/NewWeddingApi/SystemManage/Branch/SetLogImgUrl_New",
        method: "POST",
        data: list,
        success(res) {
          if (res && res.data.status) {
            this.$Success("操作成功");
          } else {
            this.$Error("操作失败");
          }
        },
        error(err) {
          this.$Error("操作失败");
          console.log(err);
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.back-ground-main {
  width: 100%;
  height: 100%;
  padding: 16px 24px 30px;
  box-sizing: border-box;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  .imgbox-box {
    display: flex;
    flex-wrap: wrap;
  }

  .img-box {
    width: 134px;
    height: 76px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-radius: 2px;
    margin: 6px 6px 0 0;
    background: #fefefe;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .back-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
    .video-mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background: rgba($color: #000000, $alpha: 0.2);
      user-select: none;
      cursor: pointer;
      &:hover {
        .del-icon {
          opacity: 1;
        }
      }
      .select-icon {
        font-size: 20px;
        object-fit: cover;
        position: absolute;
        color: #ffcc00;
        top: 8px;
        right: 8px;
        border-radius: 50%;
        background: #ffffff;
      }
      .player-icon {
        width: 16px;
        height: 16px;
        object-fit: cover;
        position: absolute;
        bottom: 9px;
        left: 9px;
      }
      .default-text {
        position: absolute;
        bottom: 10px;
        left: 30px;
        line-height: 16px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      .del-icon {
        position: absolute;
        bottom: 9px;
        right: 8px;
        font-size: 15px;
        color: #ffffff;
        cursor: pointer;
        opacity: 0;
      }
    }
  }
  .add-box {
    border: 1px dashed #cccccc;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .add-icon{
      font-size: 20px;
      color: #cccccc;
    }
  }
  .top-p {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    margin-bottom: 8px;
  }
  .btn-box {
    margin-top: 16px;
    .save-btn {
      width: 80px;
      height: 36px;
      background: #444444;
      color: #ffffff;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      border-radius: 0;
      padding: 0;
      margin-right: 16px;
    }
    .close-btn {
      width: 80px;
      height: 36px;
      background: #ffffff;
      color: #666f80;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      border-radius: 0;
      padding: 0;
      box-sizing: border-box;
      margin-left: 0;
    }
  }
}
</style>
