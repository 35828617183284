<!--
 * @Author: tangshuo
 * @Date: 2021-01-21 20:57:35
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-10-09 16:56:58
 * @Remarks:
-->
<template>
  <el-dialog
    :visible.sync="isShowDig"
    :width="width"
    top="0"
    :title="title"
    :close-on-click-modal="clickModal"
    :close-on-press-escape="pressEescape"
    @open="open"
    @opened="$emit('opened')"
    @closed="$emit('closed')"
    :before-close="closeTipDig"
    :append-to-body="appendToBody"
    custom-class="con-dig"
  >
    <div class="el-dialog__title" slot="title">{{title}}<slot name="title-text"></slot></div>
    <div
        class="dialog-main"
        :style="{ maxHeight: maxHeight + 'px',height: dialogMaxHeight ? maxHeight + 'px':'auto', }"
        @scroll="$emit('scrollMain')"
    >
      <slot name="dialogMain"></slot>
    </div>
    <span slot="footer" class="dialog-footer" v-if="isShowFooter">
      <slot name="footerLeftSlot"
        ><a
          v-if="showLeftSlot"
          class="footer-left-slot"
          @click="$emit('leftSlotClick')"
          >{{ leftSlotText }}</a></slot>
      <ConButton
        v-if="isShowCancel"
        :type="cancelType"
        :title="cancelText"
        @click="cancelClick"
      ></ConButton>
      <!--   重置按钮     -->
      <ConButton
          v-if="isShowReset"
          type="cancel"
          :title="resetText"
          @click="resetClick"
      ></ConButton>
      <ConButton
        :type="confirmType"
        :title="confirmText"
        :loading="loading"
        @click="confirmClick"
      ></ConButton>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      maxHeight: 800,
    };
  },
  props: {
    title: {
      //弹窗标题
      default: "",
    },
    isShowDig: {
      //是否显示弹窗
      default: false,
    },
    appendToBody: {
      //是否插入到body
      default: false,
    },
    isShowFooter: {
      //是否显示底部操作
      default: true,
    },
    width: {
      //弹窗宽度
      default: "480px",
    },
    confirmType: {
      //确认按钮类型
      type: String,
      default: "con",
    },
    cancelType: {
      //取消按钮类型
      type: String,
      default: "cancel",
    },
    confirmText: {
      //确认按钮文字
      type: String,
      default: "确认",
    },
    cancelText: {
      //取消按钮文字
      type: String,
      default: "取消",
    },
    isShowReset: {
      //重置按钮类型
      type: Boolean,
      default: false,
    },
    resetText: {
      //重置文字
      type: String,
      default: "重置",
    },
    loading: {
      //确认按钮是否加载中
      type: Boolean,
      default: false,
    },
    showLeftSlot: {
      //是否显示左侧文字
      type: Boolean,
      default: false,
    },
    leftSlotText: {
      //左侧文字
      type: String,
      default: "",
    },
    clickModal: {//是否可以通过点击 modal 关闭 Dialog
      type: Boolean,
      default: false,
    },
    pressEescape: {//是否可以通过按下 ESC 关闭 Dialog
      type: Boolean,
      default: false,
    },
    isShowCancel: {
      type: Boolean,
      default: true,
    },
    dialogMaxHeight: {
      //弹窗 固定高度
      type: Number,
      default: 0,
    },
  },
  created() {},
  mounted() {},
  methods: {
    open() {
      this.$nextTick(() => {
        this.getDialogMaxHeight()
      })
      this.$emit('open');
    },
    //计算高度 使弹窗居中显示
    getDialogMaxHeight(){
      this.maxHeight = this.dialogMaxHeight ? Math.min(this.dialogMaxHeight, window.innerHeight -
          150) : window.innerHeight - 150;
    },
    confirmClick() {
      this.$emit("confirmClick");
    },
    cancelClick() {
      this.$emit("cancelClick");
    },
    // 重置
    resetClick() {
      this.$emit("resetClick");
    },
    closeTipDig() {
      this.$emit("closeDig");
    },
  },
};
</script>

<style lang="scss">
.dialog-main {
  box-sizing: border-box;
  overflow: auto;
}

.dialog-main::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.dig-main {
  width: 100%;
}
.dialog-footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  .el-button {
    min-width: 80px;
  }
  .footer-left-slot {
    position: absolute;
    left: 0;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #0093ff !important;
    line-height: 20px;
    cursor: pointer;
    user-select: none;
    float: left;
    margin-top: 8px;
    &:hover {
      text-decoration: underline;
    }
  }
}
.con-dig {
  .el-dialog__header {
    height: 48px;
    padding: 0;
    background: #f5f5f5;
    @include themify($themes) {
      background: themed("search-his-bg1");
    }
    display: flex;
    align-items: center;
    padding: 0 24px;
    .el-dialog__title {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      @include themify($themes) {
        color: themed("input-color");
      }
    }
    .el-dialog__headerbtn {
      top: 0;
      right: 0;
      &:hover {
        .el-dialog__close {
          background: #e6e6e6;
          color: #222222;
          @include themify($themes) {
            background: themed("close-btn-bg");
            color: themed("input-color");
          }
        }
      }
      .el-dialog__close {
        display: inline-block;
        width: 48px;
        height: 48px;
        text-align: center;
        line-height: 48px;
        font-size: 20px;
        color: #999999;
      }
    }
  }
  .el-dialog__body {
    padding: 0;
    @include themify($themes) {
      background: themed("search-bg");
    }
  }
  .el-dialog__footer {
    padding: 16px 24px;
    @include themify($themes) {
      background: themed("search-bg");
    }
  }
}
.yes-conDig .con-dig .el-dialog__header {
  background: transparent;
}
.yes-conDig .con-dig {
  @include themify($themes) {
    background: themed("search-bg");
  }
}
.radius-conDig .con-dig {
  @include themify($themes) {
    border-radius: themed('border-radius');
    overflow: hidden;
  }
  .con-button {
    @include themify($themes) {
      border-radius: themed('border-radius-min');
    }
  }
}
</style>
