<!--
 * @Author: tangshuo
 * @Date: 2021-12-21 14:59:08
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-02-10 17:56:27
 * @Remarks: 颜色管理
-->
<template>
  <ConDig
    title="作品颜色管理"
    width="480px"
    :isShowDig="showManageDig"
    :loading="isLodding"
    :appendToBody="true"
    :isShowFooter="false"
    :clickModal="false"
    @open="initData"
    @closed="handleClose"
    @closeDig="handleClose"
    @cancelClick="handleClose"
  >
    <main slot="dialogMain" class="color-manage-box">
      <el-button
        class="con-button add-btn"
        icon="iconfont icon_add_big"
        @click="
          curColor = null;
          showAddColorDig = true;
        "
        >新增颜色</el-button
      >
      <draggable
        v-model="colorList"
        :forceFallback='true'
        :options="{
          animation: 500,
          ghostClass: 'sortable-ghost',
          chosenClass: 'sortable-chosen',
          handle: '.paixu-icon',
        }"
        draggable=".color-item"
        @change="change"
        class="color-item-box small-scroll"
      >
        <div class="color-item" v-for="color of colorList" :key="color.ID">
          <i
            class="color-icon"
            :class="{ 'color-fff': color.ColorValue === '#FFFFFF' }"
            :style="{ background: color.ColorValue }"
          ></i>
          <span class="item-name">{{
            color.ColorName ? color.ColorName.split(",")[0] : ""
          }}</span>
          <div class="btns-box">
            <ConIcon
              @click="
                curColor = color;
                showAddColorDig = true;
              "
              icon="iconfont icon_edit"
            ></ConIcon>
            <ConIcon
              icon="iconfont icon_delete"
              @click="showDelTip(color)"
            ></ConIcon>
            <ConIcon class="paixu-icon" icon="iconfont paixu"></ConIcon>
          </div>
        </div>
      </draggable>
      <ConTip
        type="warn"
        :isShowTip="showTipDig"
        cancelText="再想想"
        confirmText="删除"
        confirmType="err"
        :tipText="tipText"
        @confirmClick="confirmDelColor()"
        @closeTipDig="showTipDig = false"
        @cancelClick="showTipDig = false"
      ></ConTip>
      <AddColor
        :showAddColorDig="showAddColorDig"
        :curColor="curColor"
        @handleClose="closeAddDig"
      ></AddColor>
    </main>
  </ConDig>
</template>

<script>
import {
  getBranchColorList,
  colorDoSort,
  deleteBranchColor,
  judgeBranchColorRelation,
} from "@/api/case";
import AddColor from "./addColor";
import draggable from "vuedraggable";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      showAddColorDig: false, //是否显示新增(编辑)颜色弹窗
      isLodding: false,
      showTipDig: false, //是否显示删除提示
      colorList: [],
      tipText: "",
      curColor: null, //当前操作颜色
    };
  },
  props: {
    showManageDig: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    draggable,
    AddColor,
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions({
      getFileQDYSInfo: "caseR/getFileQDYSInfo",
    }),
    initData() {
      this.isChange = false;
      this.getBranchColorList();
    },
    /**
     * 显示删除提示
     */
    showDelTip(color) {
      this.curColor = color;
      judgeBranchColorRelation({
        ID: this.curColor.ID,
      })
        .then((res) => {
          if (res.status) {
            if (res.data) {
              this.tipText = "该颜色已关联案例，删除后相关案例的当前颜色信息也将会丢失，确定删除吗？";
            } else {
              this.tipText = "确认删除该颜色吗?";
            }
            this.showTipDig = true;
          } else {
            this.$Error(res.msg ? res.msg : "删除失败");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * 确认删除颜色
     */
    confirmDelColor() {
      if (!this.curColor || !this.curColor.ID) {
        this.$Error("数据异常,请刷新重试");
        this.showTipDig = false;
        return;
      }
      deleteBranchColor({
        ID: this.curColor.ID,
        IsCheck: false,
      })
        .then((res) => {
          if (res.status) {
            this.colorList = this.colorList.filter(
              (c) => c.ID != this.curColor.ID
            );
            this.isChange = true;
          } else {
            this.$Error(res.msg ? res.msg : "删除失败");
          }
          this.showTipDig = false;
        })
        .catch((err) => {
          console.log(err);
          this.$Error("删除失败");
          this.showTipDig = false;
        });
    },
    /**
     * 关闭新增颜色弹窗
     */
    closeAddDig(isChange = false) {
      if (isChange) {
        //刷新数据
        this.isChange = true;
        this.getBranchColorList();
      }
      this.showAddColorDig = false;
    },
    /**
     * 获取颜色列表
     */
    getBranchColorList() {
      getBranchColorList()
        .then((res) => {
          if (res.status) {
            this.colorList = res.data;
          } else {
            this.$Error(res.msg ? res.msg : "获取颜色失败");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Error("获取颜色失败");
        });
    },
    /**
     * 顺序改变
     */
    change(evt) {
      console.log(evt.moved);
      let changeId = evt.moved.element.ID;
      let firstId = 0;
      let secondId = 0;
      if (evt.moved.newIndex > 0) {
        firstId = this.colorList[evt.moved.newIndex - 1].ID;
      }
      if (evt.moved.newIndex < this.colorList.length - 1) {
        secondId = this.colorList[evt.moved.newIndex + 1].ID;
      }
      colorDoSort({
        ChangeId: changeId,
        FirstId: firstId,
        SecondId: secondId,
      })
        .then((res) => {
          if (res.status) {
            this.isChange = true;
          } else {
            this.$Error(res.msg ? res.msg : "排序失败");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Error("排序失败");
        });
    },
    handleClose() {
      if (this.isChange) {
        this.getFileQDYSInfo();
      }
      this.$emit("handleClose", this.isChange);
    },
  },
};
</script>

<style scoped lang="scss">
.color-manage-box {
  padding: 16px 0;
  .add-btn {
    margin-left: 24px;
    margin-bottom: 16px;
  }
  .color-item-box {
    width: 100%;
    height: 430px;
    overflow: auto;
    padding: 0 24px;
    box-sizing: border-box;
    .color-item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0 8px;
      box-sizing: border-box;
      position: relative;
      &:hover {
        background: #eeeeee;
        @include themify($themes) {
          background: themed("hover-bg");
        }
        .btns-box {
          opacity: 1;
        }
      }
      .color-icon {
        display: inline-block;
        width: 48px;
        height: 24px;
        margin-right: 4px;
      }
      .color-fff {
        border: 1px solid #eee;
        box-sizing: border-box;
      }
      .item-name {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        @include themify($themes) {
          color: themed("hotel-color");
        }
      }
      .btns-box {
        position: absolute;
        right: 0;
        opacity: 0;
      }
    }
  }
}
</style>
